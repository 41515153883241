<template>
  <div v-if="event != null">
        <slot name="header"></slot>
        <div class="header" v-if="showTypeAndScope">{{ event.COMPETITION.COMPETITION_TYPE.name }} - échelon {{ event.COMPETITION.SCOPE.name }}</div>
        <h3 class="title">{{ event.COMPETITION.name }} - Manche {{ event.ROUND.round_number }}</h3>
        <h2 class="title">{{ event.EVENT.name}} {{ event.CATEGORY.name }}</h2>
        <h2 class="title"><slot name="title"></slot></h2>
        <slot>
        </slot>
        <b-button v-if="showBackButton" class="back" @click="goBack"><slot name="backButtonText">&lt;&lt; Retour</slot></b-button>
        <slot name="corrections">
          <errors-viewer :errors="event.configurationErrors"></errors-viewer>
          <!--<fieldset class="fieldset danger" v-show="showErrors || event.configurationErrors.length > 0">
            <legend class="element-to-correct">A corriger...</legend>-->
            <!--<ul v-if="competitionLevelErrors.length > 0" @click="gotoCompetitionEdit">
              <li v-for="element in competitionLevelErrors" :key="element">{{ element }}</li>
            </ul>
            <ul v-if="roundLevelErrors.length > 0" @click="gotoRoundPrepare">
              <li v-for="element in roundLevelErrors" :key="element">{{ element }}</li>
            </ul>-->
          <!--  <ul>
              <li v-for="element in event.configurationErrors" :key="element" class="element-to-correct">
                {{ element }}
              </li>
            </ul>
          </fieldset>-->
          <fieldset class="fieldset warning" v-show="showWarnings || warning.length > 0">
            <legend class="Warning-element">Attention</legend>
            <ul>
              <li v-for="element in warning" :key="element" class="warning-element">
                {{ element }}
              </li>
            </ul>
          </fieldset>
        </slot>
        <slot name="footer"></slot>
  </div>
</template>

<script>
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import RoundsConfigurationHelper from '@/services/helpers/roundsConfigurationHelper';
import EventsConfigurationHelper from '@/services/helpers/eventsConfigurationHelper';
import ErrorsViewer from '../../components/ErrorsViewer.vue';

export default {
  components: { ErrorsViewer },
  props:{
    value: {type : Object, required: false},
    showErrors: { type:Boolean, default: false },
    showWarnings: { type:Boolean, default: false },
    showTypeAndScope: { type: Boolean, default: true},
    showBackButton: { type: Boolean, default: false},
  },

  computed:{
        event(){
          return (this.value == null) ? this.currentEvent : this.value;
        },
        competitionLevelErrors()
        {
          return (this.event != null)
            ? CompetitionsConfigurationHelper.missingRequirementsBeforeRun(this.event.Competition_id)
            : '';
        },
        roundLevelErrors()
        {
          return (this.event != null)
            ? RoundsConfigurationHelper.missingRequirementsBeforeRun(this.event.ROUND)
            : '';
        },
        errors(){
            return (this.event != null)
                ? EventsConfigurationHelper.missingRequirementsBeforeRun(this.event)
                : '';
        },
        warning(){
          return (this.event != null)
                ? ''
                : '';
        }
    },
}
</script>

<style scoped>
  div.header { font-size: 0.7rem; }
  element-to-correct { color: red}
  warning-element { color: orange}

  h3.title { font-size:0.7rem; }
  h2.title { font-size:0.8rem;}
</style>
